import type { $Fetch } from 'ofetch'
import MarkdownIt from 'markdown-it'
import StrapiService from '@/services/strapiService'
import type { CmsCluster } from '@/services/strapiService/types'

export default defineNuxtPlugin(({ $config }) => {
  const logger = useLogger()

  const strapiService = new StrapiService({
    apiFetch: globalThis.$fetch as $Fetch,
    apiUrl: $config.public.cmsStrapiDomain
  })

  const getProjectRecomGroups = async (clusterSlug?: string) => {
    const groups = await strapiService.getProjectRecomGroups(clusterSlug)

    if (!groups?.data) return []

    const chips = groups.data.map((item: Record<string, any>, index: number) => {
      const chip = item.attributes
      return {
        id: item.id || index.toString(),
        title: chip.title,
        message: chip.sam_prompt,
        type: 'INITIAL',
        metadata: {
          listingIds: chip?.listing_ids?.split(',')
        }
      }
    })

    return chips
  }

  const md = new MarkdownIt()

  const getLegalPolicies = async (country?: string) => {
    const policies = await strapiService.getLegalPolicies(country)

    if (!policies?.data) return []

    const filteredPolicies = policies.data.filter((item: Record<string, any>) => {
      return item.attributes.country === country
    })

    const descriptions = filteredPolicies.map((item: Record<string, any>) => {
      return md.render(item.attributes.description)
    })

    return descriptions.length > 0 ? descriptions[0] : ''
  }

  const getAvailableClusters = async (countryCode: string) => {
    try {
      const clusters = await strapiService.getAvailableClusters(countryCode)

      return clusters.data.map(({ id, attributes }: CmsCluster) => ({
        id,
        ...attributes
      }))
    } catch (err) {
      logger.error({
        message: 'Error fetching available clusters in CMS',
        stack: err
      })

      return []
    }
  }

  return {
    provide: {
      getProjectRecomGroups,
      getLegalPolicies,
      getAvailableClusters
    }
  }
})
